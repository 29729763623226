<template>
  <div
    class="w-64 mx-4 mb-8 overflow-hidden text-center bg-white rounded-lg shadow-md"
  >
    <div class="w-full h-24 mb-16" :class="'bg-' + headerColor">
      <img
        :src="require(`@/assets/mugShots/${fileName}`)"
        :alt="fileName"
        class="w-32 h-32 mx-auto transform translate-y-8 border-4 border-white border-solid rounded-full shadow"
      />
    </div>
    <div class="px-4 pt-2 pb-4">
      <p class="m-0 text-2xl font-bold text-primary">{{ name }}</p>
      <p class="m-0 text-neutral-600">{{ title }}</p>
      <p class="mt-4 text-sm">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileName: String,
    name: String,
    title: String,
    headerColor: String
  }
};
</script>
