<template>
  <base-view id="about">
    <view-header title="About Rocky Mountain Reserve" image="about.jpg">
      "We do a good thing! Our goal is to help every participant and employer
      put hard-earned money back into their own pockets. This is a result of
      creating custom benefit plans which gets you pre-tax access to pay for
      your expenses."
      <p class="mt-4 font-bold">Bob Davis, Founder & CEO</p>
    </view-header>
    <view-section class="bg-neutral-light section">
      <h1 class="mb-8 text-4xl font-bold">Our Growth</h1>
      <div class="flex flex-col items-center sm:flex-row sm:items-start">
        <div
          class="w-full h-auto p-8 pl-6 bg-white rounded-lg shadow-md chart sm:w-1/2 sm:order-2"
        >
          <canvas id="myChart"></canvas>
        </div>

        <div class="mt-6 sm:w-1/2 sm:pr-4 sm:mt-0">
          <h2 class="mb-6 text-2xl font-bold text-primary sm:text-3xl">
            Over the past 10 years, 1,387 growth in clients
          </h2>
          <div class="prose prose-md md:prose-lg">
            <p>
              At Rocky Mountain Reserve, we focus on solving whatever problem
              you may have. Every individual in every department is dedicated to
              three main things:
            </p>
            <ul>
              <li>Administering your plans legally and technically correct</li>
              <li>Service at the highest level</li>
              <li>Using the best technology</li>
            </ul>
          </div>
        </div>
      </div>
    </view-section>
    <view-section class="section">
      <h1 class="mb-8 text-4xl font-bold">Where Are We?</h1>
      <div class="flex flex-col items-center sm:flex-row sm:items-start">
        <img
          id="map"
          src="@/assets/rmr_map.png"
          alt
          class="w-full p-4 rounded-lg shadow-md bg-neutral-lighter sm:w-1/2"
        />
        <div class="mt-6 sm:w-1/2 sm:pl-8 sm:mt-0">
          <h2 class="mb-6 text-2xl font-bold text-primary sm:text-3xl">
            Based in Littleton, CO but across the nation!
          </h2>
          <p class="prose prose-md md:prose-lg">
            Clients range from school districts, construction companies, retail,
            credit unions, ski resorts, churches, city workers, and healthcare
            professionals!
          </p>
        </div>
      </div>
    </view-section>
    <view-section class="bg-neutral-light section">
      <h1 class="mb-8 text-4xl font-bold">Meet The Team</h1>
      <div
        class="flex flex-col items-center sm:flex-row sm:flex-wrap sm:items-stretch sm:justify-around"
      >
        <AvatarCard
          fileName="BobDavis.jpg"
          name="Bob Davis"
          title="Chief Executive Officer"
          headerColor="primary"
        >
          Bob was a co-founder of Denver Reserve in 1990 and the founder of
          Rocky Mountain Reserve in 2009. Bob is a Certified Public Accountant
          with a Master of Science in Accounting with an Emphasis in Tax from
          the University of Colorado. He also has a CFCI designation from the
          Employers Council on Flexible Compensation.
        </AvatarCard>
        <AvatarCard
          fileName="TuckerDavis.png"
          name="Tucker Davis"
          title="Chief Operations Officer"
          headerColor="primary"
        >
          Tucker comes from a background in healthcare and financial services
          and is a licensed Certified Public Accountant with a Master of
          Accountancy.
        </AvatarCard>
        <AvatarCard
          fileName="BradWarnick.jpg"
          name="Brad Warnick"
          title="Chief Financial Officer"
          headerColor="primary"
        >
          Brad is a member of the 2009 founding team with 25 years of Flex
          Benefits and COBRA Third Party Administration experience. He has a
          CFCI certification from the Employers Council on Flexible
          Compensation.
        </AvatarCard>
        <AvatarCard
          fileName="JakeAnderson.jpg"
          name="Jake Anderson"
          title="Chief Administration Officer"
          headerColor="primary"
        >
          Jake had 8 years of experience in the financial services sector prior
          to his start with Rocky Mountain Reserve in 2011. He has a Master of
          Education in Corporate Training and a Bachelor of Science in Technical
          Communications.
        </AvatarCard>
      </div>

      <h2 class="mb-6 text-3xl font-bold text-primary">Account Managers</h2>
      <div
        class="flex flex-col items-center sm:flex-row sm:flex-wrap sm:items-stretch sm:justify-around"
      >
        <AvatarCard
          fileName="BridgerClayton.png"
          name="Bridger Clayton"
          headerColor="neutral-900"
        >
          With a strong background in client services, Bridger has a wealth of
          industry knowledge regarding benefit programs. He earned a Bachelor of
          Science in Finance from the University of Utah.
        </AvatarCard>
        <AvatarCard
          fileName="AnnDalton.jpg"
          name="Ann Dalton"
          headerColor="neutral-900"
        >
          Ann is committed to caring personally for clients and their individual
          needs. She strives to be responsive and accurate when questions or
          concerns arise.
        </AvatarCard>
        <AvatarCard
          fileName="VickyDaniels.jpg"
          name="Vicky Daniels"
          headerColor="neutral-900"
        >
          Vicky is a member of RMR’s 2009 founding team. She has 29 years of
          experience in the benefits administration industry and loves working
          with her clients.
        </AvatarCard>
        <AvatarCard
          fileName="JenniferFinley.jpg"
          name="Jennifer Findlay"
          headerColor="neutral-900"
        >
          Jennifer passionately works with employers to provide dedicated
          support, training, and assistance to make sure their plans run
          smoothly and efficiently.
        </AvatarCard>
        <AvatarCard
          fileName="EricaKalivas.jpg"
          name="Erica Kalivas"
          headerColor="neutral-900"
        >
          Erica has enjoyed the service industry all her life at both Rocky
          Mountain Reserve and a family-owned restaurant. She previously served
          RMR as an assistant and as a claims processor. Erica loves to learn,
          work hard, and help others.
        </AvatarCard>
        <AvatarCard
          fileName="KimKania.jpg"
          name="Kim Kania"
          headerColor="neutral-900"
        >
          Kim has over 30 years of experience in the Employee Group Health
          Industry and has been a specialist in COBRA Administration for 13
          years.
        </AvatarCard>
        <AvatarCard
          fileName="KellyPalmer.jpg"
          name="Kelly Palmer"
          headerColor="neutral-900"
        >
          Kelly is a knowledgeable company contact who places special emphasis
          on communication with clients and individual participants.
        </AvatarCard>
        <AvatarCard
          fileName="CodyWalker.jpg"
          name="Cody John Walker"
          headerColor="neutral-900"
        >
          Cody is experienced with customer service relations, operations
          efficiency, and has a demonstrated history in the human resources
          industry. He graduated from Brigham Young University with a Bachelor
          of Arts in Political Science and a Minor in Management.
        </AvatarCard>
        <AvatarCard
          fileName="GeoffWarner.jpg"
          name="Geoff Werner"
          headerColor="neutral-900"
        >
          Geoff is a certified COBRA Administration Specialist (CAS) who helps
          over 250 companies in the day to day administration of COBRA. He has a
          Bachelor of Science in Finance.
        </AvatarCard>
      </div>
      <!-- <h2 class="mb-6 text-3xl font-bold text-primary">Departments</h2>
      <div
        class="flex flex-col p-4 bg-white divide-x-0 divide-y rounded-lg shadow-md divide-neutral divide-solid sm:flex-row sm:divide-y-0 sm:divide-x"
      >
        <div class="flex-1 p-4 text-center">
          <span class="inline-block flaticon-files-and-folders text-8xl" />
          <p class="m-0 mt-4 text-2xl font-bold text-primary">
            Claims Department
          </p>
          <p class="mt-4 text-sm">
            Consistent efforts in reviewing participant claims and transactions
            to ensure maximum use of funds and legal compliance to IRS
            regulations.
          </p>
        </div>
        <div class="flex-1 p-4 text-center">
          <span class="inline-block flaticon-business text-8xl" />
          <p class="m-0 mt-4 text-2xl font-bold text-primary">
            Finance Department
          </p>
          <p class="mt-4 text-sm">
            Focused on proper plan funding, access and distribution for all
            clients Rocky Mountain Reserve services.
          </p>
        </div>
        <div class="flex-1 p-4 text-center">
          <span class="inline-block flaticon-lineal text-8xl" />
          <p class="m-0 mt-4 text-2xl font-bold text-primary">
            Shared & Client Services
          </p>
          <p class="mt-4 text-sm">
            A team dedicated to fulfilling multichannel client support requests.
            Various projects including file feed processing, training, and
            material development aimed to continually improve the
            behind-the-scenes workings of the company.
          </p>
        </div>
      </div> -->
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import Chart from "chart.js";
import AvatarCard from "./components/AvatarCard.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    AvatarCard: AvatarCard
  },
  data() {
    return {
      chartData: {
        type: "bar",
        data: {
          labels: ["2010", "2012", "2014", "2016", "2018", "2020"],
          datasets: [
            {
              label: "Total # of Clients",
              data: [50, 100, 500, 784, 1043, 1387],
              backgroundColor: [
                "rgba(46, 139, 87, 1)",
                "rgba(9, 104, 67, 1)",
                "rgba(46, 139, 87, 1)",
                "rgba(9, 104, 67, 1)",
                "rgba(46, 139, 87, 1)",
                "rgba(9, 104, 67, 1)"
              ],
              borderColor: [
                "rgba(46, 139, 87, 1)",
                "rgba(9, 104, 67, 1)",
                "rgba(46, 139, 87, 1)",
                "rgba(9, 104, 67, 1)",
                "rgba(46, 139, 87, 1)",
                "rgba(9, 104, 67, 1)"
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          aspectRatio: 1,
          tooltips: {
            enabled: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      }
    };
  },
  mounted() {
    this.createChart("myChart", this.chartData);
  },
  methods: {
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    }
  }
};
</script>
